import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { MetaFunction } from "@remix-run/cloudflare";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";

export const meta: MetaFunction = () => [
  { title: "キャラつくAI" },
  {
    name: "description",
    content: "プロフィールをもとにAIがイメージしたあなたの姿は？",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-[#F9F9F9]">
        {children}
        <ScrollRestoration />
        <Scripts />
        {/* Cloudflare Web Analytics */}
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "7f7d0f1704fc48da826c7fdc028a0fb8"}'
        ></script>
        {/* End Cloudflare Web Analytics */}
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    let message;
    switch (error.status) {
      case 401:
        message = <p>認証が必要なページです</p>;
        break;
      case 403:
        message = <p>権限が必要なページです</p>;
        break;
      case 404:
        message = <p>存在しないページです</p>;
        break;
    }

    return (
      <Layout>
        <Header />
        <main>
          <h1>
            {error.status}: {error.statusText}
          </h1>
          <p>{message}</p>
        </main>
        <Footer />
      </Layout>
    );
  }

  let errorMessage: string;

  // Errorっぽくない場合はお手上げする
  if (!isError(error)) {
    errorMessage = `不明なエラーです: ${error}`;
  } else {
    errorMessage = error.message;
  }

  return (
    <Layout>
      <Header />
      <main>
        <h1>エラーが発生しました</h1>
        <p>{errorMessage}</p>
      </main>
      <Footer />
    </Layout>
  );
}

/** nameとmessageを持つありふれたJavaScriptのErrorインターフェースを持つことを確認する */
function isError(error: unknown): error is Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const err = error as any;
  return err?.message && err?.name;
}
